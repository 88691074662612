@import "bootstrap";
body {
    padding-top: 50px;
    background-color: #671A18;
}
header {
    background-color: #A1311E;
    color: #191919;
    box-shadow: 0px 0px 5px 0px #222;
    margin-bottom: 10px;
}
.m-x-0 {
    margin-left: 0;
    margin-right: 0;
}
.t16 {
  font-size: 16px;
}
.max-h80vh {
  max-height: 80vh;
}
.arrow-left, .arrow-right {
  width: 50px;
  height: 50px;
  position: absolute;
  top: calc(50% - 25px);
  fill: #FFF;
}
.arrow-right {
  right: 10%;
}
.arrow-left {
  left: 10%;
}
.container, .container-fluid {
    background-color: #fff;
    box-shadow: 0px 0px 20px 0px #222;
    margin-bottom: 20px;
}
.navbar {
    box-shadow: 0px 0px 5px 0px #222;
}
.navbar-default,
.navbar-default .navbar-brand,
.navbar-default .navbar-nav a,
.navbar-default .navbar-nav a:hover,
.navbar-default .navbar-nav a:focus {
    color: #444 !important;
}
.dropdown-menu > .active > a,
.dropdown-menu > .active > a:focus,
.dropdown-menu > .active > a:hover {
    background-color: #E7E7E7;
}
.navbar-toggle {
  padding: 6px 12px;
}
